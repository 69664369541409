import React from 'react';
import './FaqAccordion.css';
import Accordion from 'react-bootstrap/Accordion';

function FaqAccordion() {
  return (
    <div className='faq-section'>
        <div className='container d-flex flex-column align-items-center'>
            <h2 className='text-center text-capitalize mb-5'>What Moongcca can do for you</h2>
            <p className='text-center mb-5'>Every GCC of today has evolved from a single function, labor arbitrage and cost saving center to a multi-function, regional ability — globally integrated center of excellence.
 Moongcca has been the trusted partner for multiple GCCs in:</p>
            <Accordion defaultActiveKey="" flush>
                <Accordion.Item eventKey='0'>
                    <Accordion.Header>Global Business Services:</Accordion.Header>
                    <Accordion.Body>
                       From inception to maturity,Moongcca has been instrumental in supporting GCCs in theirlocation assesment ,incorporation ,tax structures,book-keepingpayroll,transfer pricing,etc.till the company can normalize their operations using the build Operate Transfer model of staffing and running Shared Services Centers
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1'>
                    <Accordion.Header>Performance Improvement:</Accordion.Header>
                    <Accordion.Body>
                       Leveraging the four-pronged strategy of centralization ,standardlization,optimization and auromation to enhance processes to acheive performance improvement aiding in marked financial transformation. 
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2'>
                    <Accordion.Header>Digital Transformation</Accordion.Header>
                    <Accordion.Body>
                       Moongcca emerging technology consultancy combines the areas of focus,priority and clients domain and business with embedded power of the cloud ,analytics and deep learning ,enterprise mobility ,ML and AI , cyber,IoT and blockchain to devise customized and a unique digital transformation roadmap.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='3'>
                    <Accordion.Header>Risk Advisory Services:</Accordion.Header>
                    <Accordion.Body>
                       We help our clients to identify,manage and monitor all the risks in their business with a keen insight into the risks arising in their sector using different methodologies like internal audit,cybersecurity,risk assurance,forensics,financial services risk management,software assest management and risk transformation.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            
        </div>
    </div>
  )
}

export default FaqAccordion;