
import React, { useState } from 'react';
import './About.css';
import { Link } from 'react-router-dom';
import AboutUsSectionImg from '../../utils/images/about-us-section-img.jpg';
import ChooseSection from '../../components/ChooseSection/ChooseSection';
import Person from '../../utils/images/Person.jpg';
import Personn1 from '../../utils/images/Personn1.jpg';
import Personn2 from '../../utils/images/Personn2.jpg';
import Personn3 from '../../utils/images/Personn3.jpg';
import Personn4 from '../../utils/images/Personn4.jpg';
import Personn5 from '../../utils/images/Personn5.jpg';
import Personn6 from '../../utils/images/Personn6.jpg';
import Personn7 from '../../utils/images/Personn7.jpg';
import Personn9 from '../../utils/images/Personn9.jpg';

const persons = [
    {
        id: 1,
        img: [Person]
    },
    {
        id: 2,
        img: [Personn1]
    },
    {
        id: 3,
        img: [Personn2]
    },
    {
        id: 4,
        img: [Personn3]
    },
    {
        id: 5,
        img: [Personn4]
    },
    {
        id: 6,
        img: [Personn5]
    },
    {
        id: 7,
        img: [Personn6]
    },
    {
        id: 8,
        img: [Personn7]
    },
    {
        id: 9,
        img: [Personn9]
    },
];

function About() {
    const [selectedImage, setSelectedImage] = useState(null);

  const handleClick = (imgSrc) => {
    setSelectedImage(imgSrc);
  };
  return (
    <div className='about-page'>
        <header className='height-75'>
            <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
                <h1 className='text-center fw-semibold'>About Us</h1>
                <p className='text-center w-75 mb-5'>At Moon Global Capability Consulting Center, we are committed to empowering organizations to unlock their full potential and achieve sustainable growth. With a focus on leveraging data, technology, and innovation, we partner with clients to navigate complex challenges, drive transformational change, and capitalize on emerging opportunities in today's dynamic business landscape..</p>
            </div>
        </header>

        <div className='container my-5'>
            <div className="row">
                <div className='col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0'>
                    <h2 className='mb-4 mb-lg-5'>Why Choose Us?</h2>
                   
                    <p>Our mission is to enable organizations to thrive in a rapidly evolving world by delivering strategic insights, innovative solutions, and measurable results. We believe in fostering long-term partnerships with our clients, built on trust, collaboration, and a shared commitment to excellence</p>
                    <h1>Why Choose Moon Global Capability Consulting Center?</h1>
                    <p className='mb-4 mb-lg-5'>
                    At Moongcca, our purpose is Building a better working world. The insights and quality services we provide help build trust and confidence in the capital markets and in economies the world over. We develop outstanding leaders who team to deliver on our promises to all our stakeholders. In so doing, we play a critical role in building a better working world for our people, for our clients and for our communities.
                    </p>

                    <Link to="/contact">
                        <button type='button' className='btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Contact Us</button>
                    </Link>
                </div>
                <div className='col-lg-6 d-flex justify-content-center'>
                    <img src={AboutUsSectionImg} className='img-fluid w-75' alt="" />
                </div>
            </div>
        </div>

        <div className='bg-dark text-light py-5'>
            <ChooseSection />
        </div>

        <div className='bg-body-tertiary py-5'>
            <div className="container">
                <h2 className='text-center mb-5'>Gallery</h2>
                <div className='row g-4'>
                    {persons.map((person) => (
                        <div key={person.id} className='col-md-4'>
                            <img
                              src={person.img}
                              className={`img-fluid ${selectedImage === person.img ? 'expanded' : ''}`}
                              alt=""
                              onClick={() => handleClick(person.img)}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
  )
}

export default About;