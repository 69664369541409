import React from 'react';
import './Courses.css';
import { Card } from 'react-bootstrap';
import trans from '../../utils/images/trans.jpg';
import BusinessCourseImg from '../../utils/images/business-course.jpg';
import ComputerScienceCourseImg from '../../utils/images/computer-science-course.jpg';
import EducationCourseImg from '../../utils/images/education-course.jpg';
import HealthcareCourseImg from '../../utils/images/healthcare-course.jpg';
import LawCourseImg from '../../utils/images/law-course.jpg';
import MusicCourseImg from '../../utils/images/music-course.jpg';
import SportCourseImg from '../../utils/images/sport-course.jpg';
import FaqAccordion from '../../components/FaqAccordion/FaqAccordion';

const courses = [
    {
        id: 1,
        img: [trans],
        title: 'Technology Transformation',
        description: 'Technology is the business agenda. Customers’ expectations for access to technology products and services are rapidly increasing and user-centric, mobile, agile and data-driven capabilities are table stakes for every organization. Companies must consider foundational technology transformation to adapt and compete. !'
    },
    {
        id: 2,
        img: [trans],
        title: 'Tax Function',
        description: 'Technology defines and underpins the workings of the new digital tax function, making it possible to answer the mandates of the global digital economy. Managing tax big data enables efficient compliance and provides insights that facilitate strategic business decisions.'
    },
    {
        id: 3,
        img: [trans],
        title: 'IPO',
        description: 'Initial public offerings (IPOs) can help companies raise the capital they need to unlock their growth ambitions, become market leaders and provide shareholders with greater liquidity alternatives. IPO-bound companies typically run multitrack strategies, assessing mergers and acquisitions alongside an IPO, and evaluating listing options around the world to raise capital and deliver an optimal valuation to shareholders'
    },
    {
        id: 4,
        img: [trans],
        title: 'Consulting',
        description: 'Consulting at EY is building a better working world by realizing business transformation through the power of people, technology and innovation.'
    },
    {
        id: 5,
        img: [trans],
        title: 'Risk consulting Services',
        description: 'Risk leaders agenda Chief risk officers must act now turning risks into opportunities to reimagine and repurpose the organization for agility and long-term value.!'
    },
    {
        id: 6,
        img: [trans],
        title: 'Technology Consulting Services',
        description: 'Risk leaders agenda Chief Risk officers must act now turning risks into opportunities to reimagine and repurpose the organization for agility and long-term value.'
    },
    {
        id: 7,
        img: [trans],
        title: 'AI consulting services',
        description: 'Our approach to the adoption of AI and intelligent automation is human-centered, pragmatic, outcomes-focused and ethical.'
    },
        {
        id: 8,
        img: [trans],
        title: 'Tax',
        description: 'Our tax professionals offer services across all tax disciplines to help you thrive in this era of rapid change.'
    },
    {
        id: 8,
        img: [trans],
        title: 'Tax Compilance',
        description: 'Complying with tax laws and regulations that are constantly changing is a challenge. We can help from tax filing to tax planning.'
    },
    {
        id: 8,
        img: [trans],
        title: 'Digital Tax Strategy',
        description: 'EY’s broad approach to digital tax strategy, covering digital tax effectiveness, digital tax administration, tax technology and tax big data, helps you identify your tax function’s immediate challenges and develop an enhanced operating model strategy fit for the Transformative Age.'
    },
    {
        id: 8,
        img: [trans],
        title: 'Private Tax Services',
        description: 'Amidst an ever-shifting tax landscape, EY Private Tax teams are dedicated to supporting your unique ambitions. Whether you are a private business leader, business-owning family or private capital investor, together we can create, preserve and transition long-term value across generations.'
    },
    {
        id: 8,
        img: [trans],
        title: 'Assurance',
        description: 'Assurance teams serve the public interest by promoting trust and confidence in business and the capital markets.Organizations are operating in a rapidly changing business environment, with increasingly complex regulatory requirements. In such circumstances, it is vital to maintain confidence and trust.'
    },
    {
        id: 8,
        img: [trans],
        title: 'IFRS',
        description: 'Moongcca Global CRS team examines the issues faced by companies in interpreting and applying International Financial Reporting Standards (IFRS).Accounting standards are an important part of the language by which companies and other entities communicate their performance in the financial statements. International Financial Reporting Standards (IFRS) are used in more than 140 jurisdictions and are set by the International Accounting Standards Board.'
    },
    {
        id: 8,
        img: [trans],
        title: 'Transactions & Corporate Finance',
        description: 'Moongcca can help you manage the crisis and stabilize your business in the short-term. We can also help you navigate the downturn and position yourselves for the recovery or look beyond to enable your transformation through corporate strategy and transactions, M&A and divestments.'
    },
    {
        id: 8,
        img: [trans],
        title: 'Corporate Finance',
        description: 'Our corporate finance consultants help corporates, private equity, governments, sovereign wealth funds, private and family businesses, and educational institutions with their key financial issues. From portfolio review and capital allocation processes to financial planning analytics and decision support, we have the people, analytics and tools to help you better allocate capital.'
    },
    {
        id: 8,
        img: [trans],
        title: 'M&A Integration',
        description: 'We have the right people, tools, and experience to help you integrate a target successfully and enhance M&A deal value. Our M&A integration professionals can help with standing up the integration management office, synergy identification, operating model design, change management and functional integration.'
    },
    {
        id: 8,
        img: [trans],
        title: 'M&A Advisory',
        description: 'Our M&A advisory team helps enable strategic growth through better integrated and operationalized mergers and acquisitions, JVs and alliances. In a fast-paced M&A market, we help companies move quickly to refine their growth strategy, perform deal sourcing, conduct diligence and valuation and achieve greater synergies during M&A integration.!'
    },
    {
        id: 8,
        img: [trans],
        title: 'Sport Course',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam odio eos distinctio ducimus! Modi quaerat dolores illo accusamus maxime cupiditate culpa minus animi repellat autem!'
    },
];

function Courses() {
  return (
    <div className='courses-page'>
        <header className='height-75'>
            <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
                <h1 className='text-center fw-semibold'>Our Services</h1>
                <p className='text-center w-75 mb-5'>Moongcca helps clients create long-term value for all stakeholders. Enabled by data and technology, our services and solutions provide trust through assurance and help clients transform, grow and operate.</p>
            </div>
        </header>

        <div className='container py-5'>
            <div className='row g-4'>
                {courses.map((course) => (
                    <div key={course.id} className='col-lg-6'>
                        <Card className='text-white shadow scale-hover-effect'>
                            <Card.Img src={course.img} />
                            <Card.ImgOverlay className='d-flex flex-column align-items-center justify-content-center p-md-5'>
                                <Card.Title className='fs-1 text-danger'>{course.title}</Card.Title>
                                <Card.Text className='text-center'>{course.description}</Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </div>
                ))}
            </div>
        </div>

        <div className='bg-dark text-light py-5'>
            <FaqAccordion />
        </div>
    </div>
  )
}

export default Courses;