import React from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import ChooseSection from '../../components/ChooseSection/ChooseSection';
import home from '../../utils/images/home.jpg';
import FaqAccordion from '../../components/FaqAccordion/FaqAccordion';
import { Card } from 'react-bootstrap';
import company1 from '../../utils/images/company1.png';
import company2 from '../../utils/images/company2.png';
import company3 from '../../utils/images/company3.png';
import company4 from '../../utils/images/company4.png';
import company5 from '../../utils/images/company5.png';
import company6 from '../../utils/images/company6.png';
import company7 from '../../utils/images/company7.png';
import company8 from '../../utils/images/company8.png';
import company9 from '../../utils/images/company9.png';
import company10 from '../../utils/images/company10.png';
import company11 from '../../utils/images/company11.png';
import company12 from '../../utils/images/company12.png';
import company13 from '../../utils/images/company13.png';
import company14 from '../../utils/images/company14.png';
import company15 from '../../utils/images/company15.png';
import company16 from '../../utils/images/company16.png';
import company17 from '../../utils/images/company17.png';
import company18 from '../../utils/images/company18.png';
import company19 from '../../utils/images/company19.png';
import company20 from '../../utils/images/company20.png';
import company21 from '../../utils/images/company21.png';
import company22 from '../../utils/images/company22.png';
import company23 from '../../utils/images/company23.png';
import company24 from '../../utils/images/company24.png';
// import company25 from '../../utils/images/company25.png';


import Blog2Img from '../../utils/images/blog2-img.jpg';
import Blog3Img from '../../utils/images/blog3-img.jpg';

const blogs = [
    {
        id: 1, 
        description: 'In our interactions with colleges ,we find whats most valuable is the practitioners insights. Knowledge is only as useful as it can be translated into action,and therefore outcomes.The biggest gap we are trying to bridge is the ability to apply.Even when we put campus hires through a structured learning programme for six months,what we find is,thry usually have good aptitude,they are like,what do I do to translate it into something that the world considers value.We need to move application upstream into everything we do.And we have to leapfrog in this,because the opportunity for india is so big.',
        title: 'Sanjay Menon EVP &MO, PUBLICIS SAPIENT',
    },
    {
        id: 2,
       
        title: 'Sangeeta Gupta SR VP & CHEIF STRATEGY OFFICER ,NASSCOM',
        description: 'Talent is so critical to what our aspirations are.At Nasscom,we are communicating what the industry needs,back to the universities.That is the big shift we will need to make to ensure we dont have to go and sell Indias value proposition in gterms of its transformation capabilities.And today,curiosity and learnability.And today,curiosity and learnability are crucial.And learnability are crucial.And learnability is not just learning a tech language,its really the new soft skills as we are calling them.They are becoming even more important than just technology skills.Previously you would learn Java and C++ and have your carrer there.I dont think thats going to be the future now. '
    },
    {
        id: 3,
      
        title: 'Sirsha Voruganti CEO & MD ,LLOYDS TECHNOLOGY CENTER,INDIA',
        description: 'The need of the hour is really for universities to proactively come and tie up with the industry so that we truly are able to solve cutting-edge problems.If you look at a lot of research work from India,I am not too sure where they are at this point in time.We do have the biggest and brightest gtalent pool,but we are at the cutting edge of every one of the technology domains,the answer is no.The moment we are able to get ahead of that curve, and we create a talent pool of students that are really able to solve some of big problems,I think skys the limit in terms of what we can do.'
    }
];
// const Home = () => {
//   const [scrollSpeed, setScrollSpeed] = useState(20); // Initial speed in seconds
//   const [isPaused, setIsPaused] = useState(false);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       if (!isPaused) {
//         setScrollSpeed(scrollSpeed => scrollSpeed + 1); // Increase speed by 1 second
//       }
//     }, 10000); // Adjust the interval as needed

//     return () => clearInterval(interval);
//   }, [isPaused]);

//   const togglePause = () => {
//     setIsPaused(isPaused => !isPaused);
//   };

function Home() {
  return (
    <div className='home-page'>
        <header className='h-100 min-vh-100 d-flex align-items-center text-light'>
            <div className='container d-flex flex-column align-items-center'>
                <h1>Moon Global Capability Center Advisors</h1>
                <h1 className='text-center fw-semibold'>Moongcca</h1>
                <p>As Global Capability Centers (GCCs) are evolving from being a source of cost arbitrage to becoming Global Value Organizations,
                     the Moon GCC Advisory practice is supporting the GCC community in India to drive digital transformation and innovation..

</p>
                <div className='d-flex flex-column flex-sm-row align-items-center'>
                    <Link to="/courses">
                        <button type='button' className='btn btn-primary btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Our Services</button>
                    </Link>
                    <Link to="/contact">
                        <button type='button' className='btn btn-light btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Contact Us</button>
                    </Link>
                </div>
            </div>
            {/* <div className='d-flex flex-column flex-sm-row align-items-center'>
                    
                    <Link to="/contact">
                        <button type='button' className='btn btn-light btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Contact Us</button>
                    </Link>
                    <Link to="/contact">
                        <button type='button' className='btn btn-light btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Contact Us</button>
                    </Link>
                    <Link to="/contact">
                        <button type='button' className='btn btn-light btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Contact Us</button>
                    </Link>
                    <Link to="/contact">
                        <button type='button' className='btn btn-light btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Contact Us</button>
                    </Link>
                </div> */}
           
        </header>
        <h4>Our related topics</h4>
        <div className='d-flex flex-column flex-sm-row align-items-center'>
                    
                    <Link to="/Carrers">
                        <button type='button' className='btn btn-primary btn-sm mx-0 mx-sm-2 my-2 my-sm-0 ' >Consulting </button>
                    </Link>
                    <Link to="/Digital">
                        <button type='button' className='btn btn-primary btn-sm mx-0 mx-sm-2 my-2 my-sm-0'>Digital</button>
                    </Link>
                    <Link to="/Innovation">
                        <button type='button' className='btn btn-primary btn-sm mx-0 mx-sm-2 my-2 my-sm-0'>Innovation</button>
                    </Link>
                    <Link to="/Workforce">
                        <button type='button' className='btn btn-primary btn-sm mx-0 mx-sm-2 my-2 my-sm-0'>Workforce</button>
                    </Link>
                </div>
        <div className="py-5">
            {/* <ChooseSection /> */}
        </div>
        
        
        <div className='py-5 bg-light'>
            <div className="container">
                <div className='row d-flex align-items-center justify-content-around'>
                    <div className='col-lg-5'>
                        <h2 className='text-capitalize'>How Moongcca can help</h2>
                        <p>The Global Capability Center (GCC) landscape in India is transforming. Organizations are increasingly looking at GCCs to drive growth and innovation for a sustainable future. Capability Center-as-a-Service (CaaS) is a one-stop solution by EY to address your GCC needs starting from setup to scale to transformation.</p>
                        <Link to="/courses">
                            <button type='button' className='btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Learn More</button>
                        </Link>
                    </div>
                    <div className='col-lg-5 mt-5 mt-lg-0'>
                        <img src={home} className='img-fluid' alt="" />
                    </div>
                </div>
            </div>
        </div>

        <div className="py-5">
            <FaqAccordion />
        </div>
        <div className="py-5">
            <ChooseSection />
        </div>

        <div className='blog-section text-light py-5'>
            <div className='container d-flex flex-column align-items-center'>
                <h2 className='text-center text-capitalize mb-5'>Moon Global Capability Centers Advisors</h2>
                <div className='row g-4'>
                    {blogs.map((blog) => (
                        <div key={blog.id} className='col-md-6 col-lg-4'>
                            <Link to="/blog" className='text-decoration-none'>
                                <Card className='h-100 shadow scale-hover-effect'>
                                    <Card.Img variant="top" src={blog.img} />
                                    <Card.Body className='p-md-5'>
                                        <Card.Title>{blog.title}</Card.Title>
                                        <Card.Text>{blog.description}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </div>
                    ))}
                </div>
                <Link to="/blog">
                    {/* <button type='button' className='btn btn-danger btn-lg mt-5'>Read More Blogs</button> */}
                </Link>
                
            </div>
        </div>
        {/* <div className="py-5">
            <ChooseSection />
        </div> */}
        <div className="marquee-section">
        <h2 style={{ color: 'green', textAlign: 'center' ,fontWeight: 'bold'}}>MOON GROUP CLIENT</h2>
        <div className="marquee">
          <img src={company1} alt="Company 1 Logo" />
          <img src={company2} alt="Company 1 Logo" />
          <img src={company3} alt="Company 1 Logo" />
          <img src={company4} alt="Company 1 Logo" />
          <img src={company5} alt="Company 1 Logo" />
          <img src={company6} alt="Company 1 Logo" />
          <img src={company7} alt="Company 1 Logo" />
          <img src={company8} alt="Company 1 Logo" />
          <img src={company9} alt="Company 1 Logo" />
          <img src={company10} alt="Company 1 Logo" />
          <img src={company11} alt="Company 1 Logo" />
          <img src={company12} alt="Company 1 Logo" />
          <img src={company14} alt="Company 1 Logo" />
          <img src={company15} alt="Company 1 Logo" />
          <img src={company16} alt="Company 1 Logo" />
          <img src={company17} alt="Company 1 Logo" />
          <img src={company18} alt="Company 1 Logo" />
          <img src={company20} alt="Company 1 Logo" />
          <img src={company21} alt="Company 1 Logo" />
          <img src={company22} alt="Company 1 Logo" />
          <img src={company23} alt="Company 1 Logo" />
          <img src={company24} alt="Company 1 Logo" />
          
         
          {/* Add more company logos here */}
        </div>
      </div>
    </div>
  )
}

export default Home;