import React from 'react';
import './Contact.css';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

function Contact() {
  return (
    <div className='contact-page'>
        <header className='height-75'>
            <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
                <h1 className='text-center fw-semibold'>Contact Us</h1>
                
                <p className='text-center w-75 mb-5'>We appreciate your interest in Moongcca. Connect with an Moongcca professional, find an office or stay informed by subscribing to one of our newsletters.</p>
            </div>
        </header>

        <div className='container my-5 d-flex justify-content-center'>
            <Form id='contact-form'>
                {/* Your form fields */}
            </Form>
        </div>

        {/* Embedding the map */}
        <div className='container my-5 d-flex justify-content-center'>
            <iframe
              title="Map"
               src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3781.362515205798!2d73.7745930750152!3d18.602757482506835!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b96e82deb74b%3A0x4451fe4fc48bf2bb!2sMOON%20SEZ%20Consultants%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1712217779113!5m2!1sen!2sin"
              height="450"
              width="1000"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
        </div>

        <div className='bg-dark text-light p-5'>
            <div className='container'>
                <h2 className='text-center mb-5'></h2>
                <div className='row g-4'>
                   {/* Other content */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Contact;
