import React from 'react';
import './Digital.css';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import Digi1 from '../../utils/images/Digi1.jpg';
import Digi2 from '../../utils/images/Digi2.jpg';
import cons3 from '../../utils/images/cons3.jpg';
import Digi4 from '../../utils/images/Digi4.jpg';
import Digi6 from '../../utils/images/Digi6.jpg';
import Blog2Img from '../../utils/images/blog2-img.jpg';
import Blog3Img from '../../utils/images/blog3-img.jpg';
import Blog4Img from '../../utils/images/blog4-img.jpg';
import Blog5Img from '../../utils/images/blog5-img.jpg';
import Blog6Img from '../../utils/images/blog6-img.jpg';
import Blog7Img from '../../utils/images/blog7-img.jpg';
import Blog8Img from '../../utils/images/blog8-img.jpg';
import Blog9Img from '../../utils/images/blog9-img.jpg';

const blogs = [
    {
        id: 1,
        img: [Digi1],
        title: 'How influencer marketing is impacting brands in India',
        description: 'India’s influencer marketing sector is projected to reach INR3,375 crore by 2026, with a CAGR of 18%.'
    },
    {
        id: 2,
        img: [Digi2],
        title: 'Resilient momentum despite challenges: Tech services M&A in 2023',
        description: 'n late 2022 and early 2023, the pace of deal making decelerated due to the steady increase in interest rates, geopolitical uncertainties, and a reduction in technology spending. However, the global technology services M&A market remained resilient in terms of volume, with 740 deals in 2023 compared to 770 deals in 2022. The deal value, however, dropped to US$9.7b from US$34.0b. Notably, large strategic buyers opted for tuck-in acquisitions while mid-market strategic and PE buyers accounted for higher proportion of deals. As a result, large deals1  were inconspicuous, with only 15 deals exceeding US$250m and none above US$1b. .'
    },
    {
        id: 3,
        img: [cons3],
        title: 'Shaping pay in 2024: The impact of the total rewards',
        description: 'Evolving talent trends necessitates innovative total rewards strategies, focusing on workplace flexibility, upskilling and digitization. '
    },
    {
        id: 4,
        img: [Digi4],
        title: 'Revolutionizing Indias mining sector A call for technological transformation',
        description: 'Indias mining and coal sectors serve as the foundation for a thriving industrial base in the country. With abundant mineral resources, India is a global leader in the production of iron ore and coal. Despite its significant reserves, India continues to import a substantial portion of its mining needs with several challenges hindering the realization of a self-reliant mining sector. Seasonal fluctuations, stringent environmental regulations, and the labor-intensive nature of mining operations further complicate the industry landscape.'
    },
    {
        id: 5,
        img: [cons3],
        title: 'How sustainable practices ensure a green healthcare ecosystem',
        description: 'Sustainability and climate change are crucial megatrends affecting the healthcare ecosystem, impacting stakeholders such as investors, customers, regulators, and financial capital providers. The leading science research guided by planetary boundaries framework update indicates that six of the nine boundaries have been transgressed, implying that Earth is now significantly beyond the safe operating space for humanity. '
    },
    {
        id: 6,
        img: [Digi4],
        title: 'How power utilities gain from converging IT and Operational Technology',
        description: 'In todays rapidly evolving energy landscape, power utilities face significant challenges driven by rising environmental concerns, evolving customer expectations, and the imperative for operational efficiency. To thrive in this dynamic environment, power utilities must embrace the convergence of Information Technology (IT) and Operational Technology (OT). This integration unlocks new opportunities for efficiency, sustainability, and enhanced customer experiences.'
    },
    
   
];

function Blog() {
  return (
    <div className='blog-page'>
        <header className='height-75 mb-8'> {/* Added mb-4 to create a margin-bottom */}
    <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
        <h1 className='text-center fw-semibold'>Digital</h1>
        <p className='text-center w-75 mb-0'>At Moongcca, we believe that digital transformation is there to unlock human potential, and accelerate new and better ways of working. </p>
    </div>
</header>


        <div className='bg-body-tertiary py-5'>
            <div className="container">
                <div className="row g-4">
                    <h1>Our Latest Thinking </h1>
                    {blogs.map((blog) => (
                        <div key={blog.id} className='col-md-6 col-lg-6'>
                            <Link to="/blog" className='text-decoration-none'>
                            <Card className='h-100 shadow scale-hover-effect bg-dark text-light border-0 blog-card'>

                                    <Card.Img variant="top" src={blog.img} />
                                    <Card.Body className='p-md-5 d-flex flex-column align-items-center'>
                                        <Card.Title className='fs-4 mb-10 text-primary text-center'>{blog.title}</Card.Title>
                                        <Card.Text className='text-center'>{blog.description}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Blog;