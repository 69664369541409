import React from 'react';
import './Blog.css';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import home from '../../utils/images/home.jpg';
import Blog2Img from '../../utils/images/blog2-img.jpg';
import Blog3Img from '../../utils/images/blog3-img.jpg';
import Blog4Img from '../../utils/images/blog4-img.jpg';
import Blog5Img from '../../utils/images/blog5-img.jpg';
import Blog6Img from '../../utils/images/blog6-img.jpg';
import Blog7Img from '../../utils/images/blog7-img.jpg';
import Blog8Img from '../../utils/images/blog8-img.jpg';
import Blog9Img from '../../utils/images/blog9-img.jpg';

const blogs = [
    {
        id: 1,
        img: [home],
        title: 'Long-term Vision',
        description: 'Help craft a long-term vision for the capability center that aligns with the organizations goals.Consider how lunar exploration fits into the broader mission and how the center can contribute to advancing space exploration capabilities globally.'
    },
    {
        id: 2,
        img: [home],
        title: 'Technology Roadmaps',
        description: 'Develop technology roadmaps that outline the key milestones and advancements necessary to achieve the centers goals. This could include innovations in propulsion systems, life support technologies, resource utilization, and more.'
    },
    {
        id: 3,
        img: [home],
        title: 'Collaborative Partnerships:',
        description: 'Foster partnerships with other space agencies, research institutions, and private companies to leverage expertise and resources. Collaborations can accelerate progress and mitigate risks associated with lunar exploration.'
    },
    {
        id: 4,
        img: [home],
        title: 'Risk Management',
        description: 'Identify and assess potential risks, including technical challenges, regulatory hurdles, and financial constraints. Develop contingency plans to address these risks and ensure the resilience of the capability centers operations.'
    },
    {
        id: 5,
        img: [home],
        title: 'Sustainability',
        description: 'Prioritize sustainability in all aspects of lunar exploration, including resource utilization, waste management, and environmental protection. Explore innovative solutions for reducing the ecological footprint of lunar activities.'
    },
    {
        id: 6,
        img: [home],
        title: 'Talent Development',
        description: 'Invest in talent development programs to cultivate a skilled workforce capable of tackling the unique challenges of lunar exploration. Provide opportunities for training, education, and professional development to attract and retain top talent.'
    },
    {
        id: 7,
        img: [home],
        title: 'Ethical Considerations',
        description: 'Consider the ethical implications of lunar exploration, including issues related to planetary protection, cultural heritage preservation, and equitable access to resources. Ensure that the centers activities adhere to ethical standards and promote responsible stewardship of the lunar environment.'
    },
    {
        id: 8,
        img: [home],
        title: 'Public Engagement',
        description: 'Engage with the public to build support for lunar exploration and increase awareness of the centers mission and achievements. Utilize outreach programs, educational initiatives, and media campaigns to inspire the next generation of space explorers.'
    },
    {
        id: 9,
        img: [home],
        title: 'Agile Approach',
        description: 'Embrace an agile approach to innovation and decision-making, allowing the capability center to quickly adapt to changing circumstances and capitalize on emerging opportunities.'
    },
];

function Blog() {
  return (
    <div className='blog-page'>
        <header className='height-75 mb-8'> {/* Added mb-4 to create a margin-bottom */}
    <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
        <h1 className='text-center fw-semibold'>Insights</h1>
        <p className='text-center w-75 mb-0'>Asking the better questions that unlock new answers to the working world's most complex issues.</p>
    </div>
</header>


        <div className='bg-body-tertiary py-5'>
            <div className="container">
                <div className="row g-4">
                    {blogs.map((blog) => (
                        <div key={blog.id} className='col-md-6 col-lg-4'>
                            <Link to="/blog" className='text-decoration-none'>
                            <Card className='h-100 shadow scale-hover-effect bg-dark text-light border-0 blog-card'>

                                    <Card.Img variant="top" src={blog.img} />
                                    <Card.Body className='p-md-5 d-flex flex-column align-items-center'>
                                        <Card.Title className='fs-1 mb-15'>{blog.title}</Card.Title>
                                        <Card.Text className='text-center'>{blog.description}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Blog;