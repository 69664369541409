import React from 'react';
import './Carrers.css';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import cons1 from '../../utils/images/cons1.jpg';
import cons2 from '../../utils/images/cons2.jpg';
import cons3 from '../../utils/images/cons3.jpg';
import cons4 from '../../utils/images/cons4.jpg';
import cons5 from '../../utils/images/cons5.jpg';
import Blog2Img from '../../utils/images/blog2-img.jpg';
import Blog3Img from '../../utils/images/blog3-img.jpg';
import Blog4Img from '../../utils/images/blog4-img.jpg';
import Blog5Img from '../../utils/images/blog5-img.jpg';
import Blog6Img from '../../utils/images/blog6-img.jpg';
import Blog7Img from '../../utils/images/blog7-img.jpg';
import Blog8Img from '../../utils/images/blog8-img.jpg';
import Blog9Img from '../../utils/images/blog9-img.jpg';

const blogs = [
    {
        id: 1,
        img: [cons1],
        title: 'How can global Capability Centers Continuosly reinvent to deliver value?',
        description: 'As Global Capability Centers (GCCs) evolve, rethinking the strategy for performance improvement can maximize the value derived.'
    },
    {
        id: 2,
        img: [cons2],
        title: 'How can GCCs help organizations unlock the power of data?',
        description: 'As organizations become increasingly data centric, Global Capability Centers (GCCs) can fulfil the critical role of centrally governing and transforming their vast data resources..'
    },
    {
        id: 3,
        img: [cons3],
        title: 'How process mining drives business excellence with data insights',
        description: 'Process mining is a game-changing technology, which is reshaping industries and revolutionizing business processes.'
    },
    {
        id: 4,
        img: [cons4],
        title: 'Embracing the future: GCCs spearheading innovation with new-age services',
        description: 'Global Capability Centers (GCCs) are diversifying their suite of offerings by adding new-age services to their service portfolio.'
    },
    {
        id: 5,
        img: [cons4],
        title: 'Shaping pay in 2024: The impact of the total rewards',
        description: 'Evolving talent trends necessitates innovative total rewards strategies, focusing on workplace flexibility, upskilling and digitization. '
    },
    {
        id: 6,
        img: [cons1],
        title: 'Embracing the future: GCCs spearheading innovation with new-age services',
        description: 'Global Capability Centers (GCCs) are diversifying their suite of offerings by adding new-age services to their service portfolio.'
    },
    
   
];

function Blog() {
  return (
    <div className='blog-page'>
        <header className='height-75 mb-8'> {/* Added mb-4 to create a margin-bottom */}
    <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
        <h1 className='text-center fw-semibold'>Consulting</h1>
        <p className='text-center w-75 mb-0'>Consulting at Moongcca is building a better working world by realizing business transformation through the power of people, technology and innovation.</p>
    </div>
</header>


        <div className='bg-body-tertiary py-5'>
            <div className="container">
                <div className="row g-4">
                    <h1>Our Latest Thinking On Consulting</h1>
                    {blogs.map((blog) => (
                        <div key={blog.id} className='col-md-6 col-lg-6'>
                            <Link to="/blog" className='text-decoration-none'>
                            <Card className='h-100 shadow scale-hover-effect bg-dark text-light border-0 blog-card'>

                                    <Card.Img variant="top" src={blog.img} />
                                    <Card.Body className='p-md-5 d-flex flex-column align-items-center'>
                                        <Card.Title className='fs-4 mb-10 text-primary text-center'>{blog.title}</Card.Title>
                                        <Card.Text className='text-center'>{blog.description}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Blog;